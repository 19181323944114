import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

function Archive() {
  return (
    <Layout>
      <SEO
        keywords={[`Архив`, `Профф`, `Пыть-Ях`, `Профессиональное образование`]}
        title='Архив'
      />

      <section className='flex flex-col items-center '>
        <h1>Архив</h1>
      </section>
    </Layout>
  );
}

export default Archive;
